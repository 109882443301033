(function(wnd){
  function SiteHelper(params) {
    try {
      if (params) {
        this.setParams(params);
      }
    } catch (error) {

    }
  }

  SiteHelper.prototype = {
    numberFormatPattern: {
      thousandSeparator:'.',
      decimalSeparator:',',
      decimals:0
    },
    numberFormat: function(number, suffix, thousandSeparator, decimalSeparator, decimals) {
      if (!thousandSeparator) {
        thousandSeparator = this.numberFormatPattern.thousandSeparator;
      }

      if (!decimalSeparator) {
        decimalSeparator = this.numberFormatPattern.decimalSeparator;
      }

      if (!decimals) {
        decimals = this.numberFormatPattern.decimals;
      }

      number = (number + '').replace(/[^0-9+\-Ee.]/g, '');
      var n = !isFinite(+ number) ? 0 : + number;
      var prec = !isFinite(+ 2) ? 0 : Math.abs(2);
      var s = '';

      toFixedFix = function(n, prec) {
          var k = Math.pow(10, prec);
          return '' + (
          Math.round(n * k) / k).toFixed(prec);
      };

      s = (prec ? toFixedFix(n, prec) : '' + Math.round(n)).split('.');

      var formatted = s;

      if (formatted[0].length > 3) {
        formatted[0] = formatted[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, thousandSeparator);
      }

      if ((formatted[1] || '').length < prec) {
        formatted[1] = formatted[1] || '';
        formatted[1] += new Array(prec - formatted[1].length + 1).join('0');
      }

      formatted = decimals ? formatted.join(decimalSeparator) : formatted[0];

      return formatted+(suffix ? " "+suffix : "");
    },
    setParams: function(params) {
      if (params.numberFormatPattern) this.setNumberFormatPattern(params.numberFormatPattern);
    },
    setNumberFormatPattern: function(pattern) {
      this.numberFormatPattern = $.extend({}, this.numberFormatPattern, pattern);
    },
    unionArray: function(a, b) {
      return a.filter(function(item, index) {
        return b.indexOf(item) >= 0;
      });
    },
    isMobileBrowser: function() {
      var check = false;
      (function(a) {
        if (/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(a) || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(a.substr(0, 4)))
          check = true;
        }
      )(navigator.userAgent || navigator.vendor || window.opera);
      return check;
    },
    parseStringToObject: function(str) {
      var obj = {};
      var arr;
      var len;
      var val;
      var i;

      // Remove spaces before and after delimiters
      str = str.replace(/\s*:\s*/g, ':').replace(/\s*,\s*/g, ',');

      // Parse a string
      arr = str.split(',');
      for (i = 0, len = arr.length; i < len; i++) {
        arr[i] = arr[i].split(':');
        val = arr[i][1];

        // Convert a string value if it is like a boolean
        if (typeof val === 'string' || val instanceof String) {
          val = val === 'true' || (val === 'false' ? false : val);
        }

        // Convert a string value if it is like a number
        if (typeof val === 'string' || val instanceof String) {
          val = !isNaN(val) ? +val : val;
        }

        obj[arr[i][0]] = val;
      }

      return obj;
    }
  }

  wnd.SiteHelper = SiteHelper;
})(window);
