require('./core/notyf.js');

(function(wnd){
  function MessageHandler(params) {
    try {
      if (params) {
        this.setParams(params);
      }
      this.init();

      return this;
    } catch (error) {
      console.error(error);
    }
  }

  MessageHandler.prototype = {
    texts: {
      successSave: 'Sikeres mentés!',
      deleteAddressTitle: 'Cím törlése',
      deleteAddressLead: 'Biztos, hogy törli ezt a címet?',
      confirmText: 'Igen',
      cancelText: 'Mégse'
    },
    options: {
      delay: 5000,
    },
    messages: [],
    obj: undefined,
    init: function() {
      this.setElements();
      this.addDOMEvents();

      if (this.messages && this.messages.length > 0) {
        for (var i = 0; i < this.messages.length; i++) {
          this.showMessage(this.messages[i]);
        }
      }
    },
    setElements: function() {
      if (typeof Notyf !== 'undefined') {
        var delay = this.getOption('delay');
        if (!delay) {
          delay = 5000;
        }

        this.obj = new Notyf({
          delay: delay
        });
      }
    },
    addDOMEvents: function() {
      var _self = this;

      $(document).on(PROJECT_NAMESPACE+'.show_system_message', function(e, data) {
        _self.showMessage(data);
      });
    },
    setParams: function(params) {
      if (params.texts) this.setTexts(params.texts);
      if (params.options) this.setOptions(params.options);
      if (params.messages) this.setMessages(params.messages);
    },
    setTexts: function(texts) {
      this.texts = $.extend({}, this.texts, texts);
    },
    getText: function(key) {
      return typeof this.texts[key] !== 'undefined' ? this.texts[key] : key;
    },
    setOptions: function(options) {
      this.options = $.extend({}, this.options, options);
    },
    setMessages: function(messages) {
      if (Array.isArray(messages)) {
        this.messages = messages;
      }
    },
    showMessage: function(data) {
      var type = 'error';
      var message = '';
      if (typeof data === 'object') {
        message = typeof data.message !== 'undefined' ? data.message : '';
        type = typeof data.type !== 'undefined' ? data.type : type;
      } else {
        message = data;
      }

      if (message) {
        switch (type) {
          case 'error':
              this.showErrorMessage(message)
              break;
          case 'confirm':
          case 'success':
              this.showConfirmMessage(message)
              break;
        }
      }
    },
    showConfirmMessage: function(message) {
      if (this.obj) {
        this.obj.confirm(message);
      } else {
        console.log(message);
      }
    },
    showErrorMessage: function(message) {
      if (this.obj) {
        this.obj.alert(message);
      } else {
        console.error(message);
      }
    },
    getOption: function(key) {
      return typeof this.options[key] !== 'undefined' ? this.options[key] : null;
    }
  }

  wnd.MessageHandler = MessageHandler;
})(window);
