(function(wnd){
  function DynamicModalHandler(params) {
    try {
      this.setParams(params);
      this.init();
    } catch (error) {
      console.error(error);
    }
  }

  DynamicModalHandler.prototype = {
    modal: undefined,
    content: undefined,
    modalOptions: {},
    id: undefined,
    extraClass: '',
    size: '',
    title:'',
    titleClass:'',
    init: function() {
      this.createContainer();

      this.initRemodal();
    },
    initRemodal: function() {
      this.modal.remodal().open();
      this.onOpen();

      if (typeof window.lazyLoadInstance !== 'undefined') {
        window.lazyLoadInstance.update();
      }
    },
    createContainer: function() {
      var _self = this;

      this.modal = $('<div>', {
        class:'dynamic-remodal '+(this.size ? 'size-'+this.size : '')+(this.extraClass ? ' '+this.extraClass : '')
      });

      var closeButtonEnabled = true;
      var optionKeys = Object.keys(this.modalOptions);
      if (optionKeys.length > 0) {
        if (typeof this.modalOptions.withoutCloseButton && this.modalOptions.withoutCloseButton) {
          closeButtonEnabled = false;
        }

        this.modal['remodal'](this.modalOptions);
      }

      if (this.id) {
        this.modal.attr('id', this.id);
      }

      if (closeButtonEnabled) {
        this.modal.append('<span class="close-btn position-absolute" data-remodal-action="close"><svg class="icon close-icon"><use xlink:href="#close-icon" /></svg></span>');
      }

      if (this.title) {
        this.modal.append('<h2 class="modal-title '+(this.titleClass ? this.titleClass : '')+'">'+this.title+'</h2>');
      }
      this.modal.append('<div class="inner-content">'+this.content+'</div>');

      this.modal.on('closing', function(){
        _self.onClose();
        _self.modal.remodal().destroy();
      });
    },
    setParams: function(params) {
      if (typeof params.onClose === 'function') {
        this.onClose = params.onClose;
      }

      if (typeof params.onOpen === 'function') {
        this.onOpen = params.onOpen;
      }

      if (params.content) {
        this.content = params.content;
      }

      if (params.id) {
        this.id = params.id;
      }

      if (params.size) {
        this.size = params.size;
      }

      if (params.class) {
        this.extraClass = params.class;
      }

      if (params.modalTitle) {
        this.title = params.modalTitle;
      }

      if (params.modalTitleClass) {
        this.titleClass = params.modalTitleClass;
      }

      if (params.modalOptions) {
        this.modalOptions = params.modalOptions;
      }
    },
    onOpen: function() {},
    onClose: function() {}
  }

  wnd.DynamicModalHandler = DynamicModalHandler;
})(window);
