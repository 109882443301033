(function (wnd) {
  function CalculatorForm(container, params) {
    try {
      if (!container || (container && container.length === 0)) {
        throw 'Container is required';
      }

      this.container = container;
      if (params) {
        this.setParams(params)
      }

      this.init();
    } catch (error) {
      console.error(error);
    }
  }

  CalculatorForm.prototype = {
    container: undefined,
    form: undefined,
    tabs: undefined,
    sendButton: undefined,
    newRowButton: undefined,
    typeHiddenInput: undefined,
    panelButton: undefined,
    calculatorContent: undefined,
    currentIndex: 0,
    hasTabs: false,
    sendedForm: false,
    openedCalculatorContent: false,
    floatingPanelBreakpoint: 1023,
    selectors: {
      tab: ".calculator-tab-item",
      typeHiddenInput: ".type-hidden",
      sendButton: '[data-purpose="send-button"]',
      newRowButton: '[data-purpose="add-new-row"]',
      panelButton: '[data-purpose="panel-button"]',
      calculatorContent: '[data-purpose="calculator-content"]',
      calculatorCloseBtn: '[data-purpose="close-calculator"]'
    },
    options: {
      ajax: true,
      validateBeforeSend: true,
      validateOnBlur: true
    },
    fields: [{
        key: "counter",
        unit: "db",
        type: "number",
      },
      {
        key: "weight",
        unit: "kg",
        type: "number",
      },
      {
        key: "length",
        unit: "cm",
        type: "number",
        extraClass: 'size-field'
      },
      {
        key: "width",
        unit: "cm",
        type: "number",
        extraClass: 'size-field'
      },
      {
        key: "height",
        unit: "cm",
        type: "number",
        extraClass: 'size-field'
      }
    ],
    texts: {
      counterLabel: "Mennyiség",
      weight: "Súly",
      length: "Hossz",
      width: "Szélesség",
      height: "Magasság",
      reqfieldError: "Hiányzó adat!"
    },
    init: function () {
      this.setElements();
      this.addDOMEvents();

      this.currentIndex = this.container.find('.package-row').length;

      this.container.addClass('initialized');
    },
    setElements: function () {
      this.form = this.container.find('form');

      if (this.form.length === 0) {
        throw 'Container must contain a <form> html element!';
      }

      this.sendButton = this.container.find(this.getSelector('sendButton'));
      this.newRowButton = this.container.find(this.getSelector('newRowButton'));
      this.typeHiddenInput = this.container.find(this.getSelector('typeHiddenInput'));
      this.panelButton = this.container.find(this.getSelector('panelButton'));
      this.calculatorContent = this.container.find(this.getSelector('calculatorContent'));

      this.tabs = this.container.find(this.getSelector('tab'));
      if (this.tabs.length > 0) {
        this.hasTabs = true;
      }
    },
    addDOMEvents: function () {
      var _self = this;

      if (this.hasTabs) {
        this.tabs.on("click", function () {
          if (!$(this).hasClass('active')) {
            _self.handleTabChange($(this));
          }
        });
      }

      if (this.sendButton && this.sendButton.length > 0) {
        this.sendButton.on("click", function (e) {
          var valid = true;
          if (_self.getOption('validateBeforeSend')) {
            valid = _self.validateForm();

            if (!valid) {
              e.preventDefault();
            }
          }

          if (valid) {
            if (_self.getOption('ajax')) {
              _self.sendForm();
            } else {
              _self.form.submit();
            }
          }
        });
      }

      if (this.newRowButton && this.newRowButton.length > 0) {
        this.newRowButton.on('click', function () {
          _self.addNewRow();
        });
      }

      this.container.find('.package-row .delete-col a').on("click", function () {
        _self.removePackageRow($(this));
      });

      if (this.getOption('validateOnBlur')) {
        this.container.find('input:not(.selectric-input)').on('blur', function () {
          _self.validateInput($(this));
        });

        this.container.find('select').on('change', function () {
          _self.validateInput($(this));
        });
      }

      if (this.panelButton && this.panelButton.length > 0 && this.calculatorContent && this.calculatorContent.length === 1) {
        this.panelButton.on('click', function () {
          _self.handlePanelButtonClick();
        });

        this.container.find(this.getSelector('calculatorCloseBtn')).on('click', function () {
          _self.handlePanelButtonClick();
        });

        $(document).on(PROJECT_NAMESPACE + '.window_width_changed', function (e, newWidth) {
          _self.handleWindowWidthChanged(newWidth);
        });

        $(document).on(PROJECT_NAMESPACE + '.user_did_scroll', function () {
          _self.checkFloatingPanelPosition();
        });
      }
    },
    addRowDOMEvents: function (row) {
      var _self = this;

      if (this.getOption('validateOnBlur')) {
        row.find('input').on('blur', function () {
          _self.validateInput($(this));
        });

        row.find('select').on('change', function () {
          _self.validateInput($(this));
        });

        row.find('.delete-col a').on("click", function () {
          _self.removePackageRow($(this));
        });
      }
    },
    handleTabChange: function (tab) {
      this.tabs.removeClass('active');
      tab.addClass('active');

      var type = tab.data('type');
      var counterLabel = tab.data('counter-label');
      var newLabel = tab.data('new-label');

      if (this.typeHiddenInput) {
        this.typeHiddenInput.val(type);
      }

      this.container.find('.replace-text[data-key="counter-label"]').html(counterLabel);
      this.container.find('.replace-text[data-key="new-row"]').html(newLabel);
    },
    handlePanelButtonClick: function () {
      this.container.toggleClass('opened-calculator');
      this.openedCalculatorContent = this.container.hasClass('opened-calculator');

      if (window.innerWidth > this.floatingPanelBreakpoint) {
        this.calculatorContent.slideToggle();
      } else {
        $('body, html').toggleClass('scroll-disabled');
      }
    },
    handleWindowWidthChanged: function (newWidth) {
      if (this.openedCalculatorContent) {
        if (newWidth > this.floatingPanelBreakpoint && $('body').hasClass('scroll-disabled')) {
          $('body, html').removeClass('scroll-disabled');
          this.container.find('.calculator-panel').removeClass('inactive');
          this.calculatorContent.show();
        } else if (newWidth <= this.floatingPanelBreakpoint && !$('body').hasClass('scroll-disabled')) {
          $('body, html').addClass('scroll-disabled');
          this.calculatorContent.removeAttr('style');
        }
      }
    },
    checkFloatingPanelPosition: function () {
      var panel = this.container.find('.calculator-panel');
      if (panel.length === 1) {
        if (window.innerWidth <= this.floatingPanelBreakpoint) {
          var borderItem = $('.offers-page-content');
          if (borderItem.length == 0) {
            borderItem = $('.main-content');
          }

          if (borderItem.length === 1) {
            var bottomBorder = borderItem.offset().top + borderItem.outerHeight();
            var checkPosition = $(window).scrollTop() + window.innerHeight;

            if (checkPosition >= bottomBorder && !panel.hasClass('inactive')) {
              panel.addClass('inactive');
            } else if (checkPosition < bottomBorder && panel.hasClass('inactive')) {
              panel.removeClass('inactive');
            }
          }
        } else if (panel.hasClass('inactive')) {
          panel.removeClass('inactive');
        }
      }
    },
    addNewRow: function () {
      var index = this.currentIndex;
      var rowCount = this.container.find('.package-row').length;

      var row = $('<div>', {
        class: "package-row display-grid",
        'data-index': index
      });

      for (var i = 0; i < this.fields.length; i++) {
        var field = this.fields[i];
        var key = field.key;

        var col = $('<div>', {
          class: 'col ' + key + '-col',
        });

        var labelText = typeof field.label !== 'undefined' ? field.label : '';
        var unit = typeof field.unit !== 'undefined' ? field.unit : '';
        if (!labelText) {
          if (key == 'counter') {
            if (this.hasTabs) {
              var tab = this.container.find('.calculator-tab-item.active');
              if (tab && tab.length === 1) {
                labelText = tab.data('counter-label');
              }
            }

            if (!labelText) {
              var firstRow = this.container.find('.package-row').first();
              if (firstRow && firstRow.length == 1) {
                labelText = firstRow.find('.replace-text[data-key="counter-label"]').html();
              }
            }

            if (!labelText) {
              labelText = this.getText('counterLabel');
            }
          } else {
            labelText = this.getText(key);
          }
        }

        var fieldId = key + '_' + index;
        var label = $('<label>', {
          html: labelText,
          for: fieldId
        });

        if (key == 'counter') {
          label.addClass('replace-text');
          label.attr('data-key', 'counter-label');
        }

        col.append(label);

        if (key == 'counter') {
          col.append('<div class="counter"><div class="inner"><span class="hash">#</span><span class="number">' + (rowCount + 1) + '</span></div></div>');
        }

        var fieldContainer = $('<div>', {
          class: "field-container"
        });

        var inputType = typeof field.type !== 'undefined' && field.type ? field.type : 'text';
        var extraClass = typeof field.extraClass !== 'undefined' && field.extraClass ? field.extraClass : '';

        fieldContainer.append($('<input />', {
          type: inputType,
          name: key + '[' + index + ']',
          class: 'field ' + key + '-field' + (extraClass ? ' ' + extraClass : ''),
          id: fieldId
        }));

        if (unit) {
          fieldContainer.append('<label for="counter_' + index + '" class="unit-label">' + unit + '</label>');
        }

        col.append(fieldContainer);

        row.append(col);
      }

      var deleteCol = $('<div>', {
        class: "col delete-col display-flex align-items-center",
        html: $('<a>', {
          url: 'javascript:void(0)',
          class: 'zero-text',
          html: svg('trash-icon')
        })
      });

      row.append(deleteCol);

      this.container.find('.packages-content').append(row);
      this.addRowDOMEvents(row);

      this.currentIndex++;
    },
    removePackageRow: function (btn) {
      var row = btn.parents('.package-row');
      row.find('input').off('blur');
      btn.off('click');
      $(document).trigger(PROJECT_NAMESPACE+'.remove_package_row', row.data('index'));

      row.remove();

      var counter = 1;
      this.container.find('.package-row').each(function () {
        $(this).find('.counter .number').html(counter);
        counter++;
      });
    },
    sendForm: function () {
      if (!this.sendedForm && this.getOption('ajax')) {
        var _self = this;

        this.sendedForm = true;
        var methodType = this.form.attr('method');
        if (!methodType) {
          methodType = 'post';
        }

        var datas = this.form.serialize();

        $.ajax({
          url: this.form.attr('action'),
          dataType: 'json',
          type: methodType,
          data: datas,
          success: function (response) {
            if (response.success) {

              $(document).trigger(PROJECT_NAMESPACE + '.success_form_submit', {
                form: _self.form,
                datas: datas,
                key: _self.form.data('key'),
                response: response
              });

              _self.handleSuccessSend(response);
            } else {
              if (typeof response.errors === 'object') {
                $.each(response.errors, function (group, values) {
                  if (typeof values === 'string') {
                    var input = _self.form.find('[name="' + group + '"]');
                    if (input && input.length > 0) {
                      _self.handleFieldError(false, input, values);
                    }
                  } else if (typeof values === 'object') {
                    $.each(values, function (index, value) {
                      if (typeof value === 'string' && value.trim() !== '') {
                        var input = _self.form.find('[name="' + group + '[' + index + ']"]');
                        if (input && input.length > 0) {
                          _self.handleFieldError(false, input, value);
                        }
                      }
                    });
                  }
                });
              }

              $(document).trigger(PROJECT_NAMESPACE + '.errored_form_submit', {
                form: _self.form,
                errors: typeof response.errors !== undefined ? response.errors : false,
                datas: datas,
                key: _self.form.data('key'),
                response: response
              });
            }
          },
          complete: function () {
            setTimeout(function () {
              _self.sendedForm = false;
            }, 400);
          }
        });
      } else if (!this.getOption('ajax')) {
        this.form.submit();
      }
    },
    setParams: function (params) {
      if (params.selectors) this.setSelectors(params.selectors);
      if (params.texts) this.setTexts(params.texts);
      if (params.options) this.setOptions(params.options);
      if (params.fields) this.setFields(params.fields);

      if (typeof params.sendForm === 'function') {
        this.sendForm = params.sendForm;
      }

      if (typeof params.validateForm === 'function') {
        this.validateForm = params.validateForm;
      }

      if (typeof params.validateInput === 'function') {
        this.validateInput = params.validateInput;
      }

      if (typeof params.handleFieldError === 'function') {
        this.handleFieldError = params.handleFieldError;
      }

      if (typeof params.handleSuccessSend === 'function') {
        this.handleSuccessSend = params.handleSuccessSend;
      }
    },
    setSelectors: function (selectors) {
      this.selectors = $.extend({}, this.selectors, selectors);
    },
    setTexts: function (texts) {
      this.texts = $.extend({}, this.texts, texts);
    },
    setOptions: function (options) {
      this.options = $.extend({}, this.options, options);
    },
    setFields: function (fields) {
      this.fields = fields;
    },
    getText: function (key) {
      return this.getObjectValue('texts', key);
    },
    getSelector: function (key) {
      return this.getObjectValue('selectors', key);
    },
    getOption: function (key) {
      return this.getObjectValue('options', key);
    },
    getObjectValue: function (object, key) {
      if (typeof this[object] === 'undefined') {
        throw 'Undefined property ' + object + '!'
      }

      if (typeof this[object][key] === 'undefined') {
        throw 'Undefined ' + object + ' key: ' + key + '!'
      }

      return this[object][key];
    },
    validateForm: function () {
      var _self = this;
      var valid = true;

      this.form.find('input:not(.selectric-input), select').each(function () {
        var validField = _self.validateInput($(this));
        if (!validField) {
          valid = false;
        }
      });

      return valid;
    },
    validateInput: function (input) {
      var valid = true;
      var value = $(input).val();
      var error = '';

      if (value.trim() == '') {
        error = this.getText('reqfieldError');
        valid = false;
      }

      this.handleFieldError(valid, input, error);

      return valid;
    },
    handleSuccessSend: function (response) {
      if (typeof response.url !== 'undefined' && response.url) {
        document.location.href = response.url;
      }
    },
    handleFieldError: function (valid, input, error) {
      var parent = $(input).parents('.field-container');

      if (valid && input.hasClass('has-error')) {
        input.removeClass('has-error');
        parent.find('.error-content').remove();
      } else if (!valid) {
        parent.find('.error-content').remove();
        input.addClass('has-error');

        if (error) {
          parent.append('<div class="error-content">' + error + '</div>');
        }
      }
    }
  }

  wnd.CalculatorForm = CalculatorForm;

})(window);
