require('./DynamicModalHandler.js');

(function(wnd){
  function SiteEventHandler(params) {
    try {
      if (params) {
        this.setParams(params);
      }

      this.init();
    } catch (error) {
      console.error(error);
    }
  }

  SiteEventHandler.prototype = {
    userDidScroll: false,
    windowWidth: undefined,
    selectors: {
      helloBarCloseBtn:'[data-purpose="close-hello-bar"]'
    },
    attributeNames: {},
    options: {
      defaultFormErrorDisplayMode: 'errorField',
      scrollToFirstInvalidField: true,
      scrollIntervalTimeout: 100,
    },
    customDOMEvents: function() {},
    init: function() {
      this.addDOMEvents();
      this.addDOMEventListeners();

      if (typeof this.customDOMEvents === 'function') {
        this.customDOMEvents();
      }
    },
    addDOMEvents: function() {
      var _self = this;

      /** scroll esemény figyelés */
      $(window).on('scroll touchmove', function(){
        _self.userDidScroll = true;
      });

      setInterval(function(){
        if (_self.userDidScroll) {
          _self.userDidScroll = false;
          $(document).trigger(PROJECT_NAMESPACE+'.user_did_scroll');
        }
      }, _self.getOption('scrollIntervalTimeout'));

      $(document).find(this.getSelector('helloBarCloseBtn')).on('click', function(){
        if (!$(this).hasClass('closed')) {
          $(this).addClass('closed');
          $(this).parent().slideToggle();

          $(document).trigger(PROJECT_NAMESPACE+'.close_hello_bar', {
            button: $(this)
          });
        }
      });

      /** kijelző szélesség változás */
      var resizeFn = function() {
        if (window.innerWidth != _self.windowWidth) {
          _self.windowWidth = window.innerWidth;
          $(document).trigger(PROJECT_NAMESPACE+'.window_width_changed', _self.windowWidth);
        }
      };

      window.addEventListener('resize', resizeFn);
    },
    addDOMEventListeners: function() {
      var _self = this;

      $(document).on(PROJECT_NAMESPACE+'.handle_form_errors', function(evt, data) {
        var mode = typeof data.mode !== 'undefined' ? data.mode : _self.getOption('defaultFormErrorDisplayMode');
        var scrollToFirstInvalidField = typeof data.scrollToFirstInvalidField !== "undefined" ? data.scrollToFirstInvalidField : _self.getOption('scrollToFirstInvalidField');
        _self.handleFormErrors(data.form, data.errors, mode, scrollToFirstInvalidField);
      });

      $(document).on(PROJECT_NAMESPACE+'.open_dynamic_modal', function(evt, data) {
        _self.handleDynamicModalOpen(data);
      });
    },
    setParams: function(params) {
      if (params.selectors) this.setSelector(params.selectors);
      if (params.attributeNames) this.setAttributeNames(params.attributeNames);
      if (params.options) this.setOptions(params.options);

      if (typeof params.customDOMEvents === 'function') {
        this.customDOMEvents = params.customDOMEvents;
      }
    },
    setSelector: function(selectors) {
      this.selectors = $.extend({}, this.selectors, selectors);
    },
    setAttributeNames: function(attributeNames) {
      this.attributeNames = $.extend({}, this.attributeNames, attributeNames);
    },
    setOptions: function(options) {
      this.options = $.extend({}, this.options, options);
    },
    getSelector: function(key) {
      return this.getObjectValue('selectors', key);
    },
    getAttributeName: function(key) {
      return this.getObjectValue('attributeNames', key);
    },
    getOption: function(key) {
      return this.getObjectValue('options', key);
    },
    getObjectValue: function(object, key) {
      if (typeof this[object] === 'undefined') {
        throw 'Undefined property '+object+'!'
      }

      if (typeof this[object][key] === 'undefined') {
        throw 'Undefined '+object+' key: '+key+'!'
      }

      return this[object][key];
    },
    handleFormErrors: function(form, errors, mode, scrollToFirstInvalidField) {
      if (typeof errors === 'string') {
        this.showSystemMessage(errors, 'error');
      } else if (typeof errors === 'object') {
        var firstFieldTop = undefined;

        var _self = this;
        var showedMessages = [];

        if (!mode) {
          mode = this.getOption('defaultFormErrorDisplayMode');
        }

        $.each(errors, function(group, values){
          var input = form.find('[name="'+group+'"]');
          if (typeof values === 'string') {
            var fieldTop = input.parent().offset().top;
            if (firstFieldTop === undefined || (firstFieldTop !== undefined && parseInt(firstFieldTop) > fieldTop)) {
              firstFieldTop = fieldTop;
            }
            if (mode == 'alert') {
              if (values.trim() !== '' && showedMessages.indexOf(values) < 0) {
                showedMessages.push(values);
                _self.showSystemMessage(values, 'error');
              }
            }

            if (input && input.length > 0) {
              _self.handleFieldError(input, values, mode);
            }
          } else if (typeof values === 'object') {
            $.each(values, function(index, value) {
              if (mode == 'alert') {
                if (typeof value === 'string' && value.trim() !== '') {
                  if (showedMessages.indexOf(value) < 0) {
                    showedMessages.push(value);
                    _self.showSystemMessage(value, 'error');
                  }
                }
              }

              var input = form.find('[name="'+group+'['+index+']"]');
              if (firstFieldTop === undefined || (firstFieldTop !== undefined && parseInt(firstFieldTop) > fieldTop)) {
                firstFieldTop = fieldTop;
              }
              if (input && input.length > 0) {
                _self.handleFieldError(input, error, mode);
              }
            });
          }
        });

        if (scrollToFirstInvalidField) {
          var offset = $('body').hasClass('opened-fix-header') ? $('.main-header .fix-content').outerHeight(true) : $('.main-header').outerHeight(true);
          $('html, body').animate({scrollTop: firstFieldTop - offset}, "normal");
        }
      }
    },
    handleDynamicModalOpen: function(data) {
      new DynamicModalHandler(data);
    },
    handleFieldError: function(input, error, mode) {
      input.addClass("has-error");

      var id = input.attr('id');
      if (id && !input.is(':radio') && !input.is(':checkbox')) {
        var label = $('label[for="'+id+'"]');
        if (label && label.length === 1) {
          label.addClass('has-error');
        }
      }

      if (mode == 'errorField' && typeof error === 'string' && error.trim()) {
        var parent = input.parent();
        parent.append('<div class="error-content">'+error+'</div>');
      }
    },
    showSystemMessage: function(message, type) {
      if (!type) {
        type = 'success';
      }
      if (message.trim() == '') {
        return;
      }

      $(document).trigger(PROJECT_NAMESPACE+'.show_system_message', {
        type: type,
        message: message
      });
    }
  }

  wnd.SiteEventHandler = SiteEventHandler;
})(window);
