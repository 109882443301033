function AccordionList($element, params) {
  try {
    if (!$element) {
      throw "Element is required!";
    }
    this.element = $element;
    this.options = $.extend({}, this.defaults, params);

    this.init();
  } catch (error) {
    console.error(error);
  }
}

AccordionList.prototype = {
  element: undefined,
  defaults: {
    singleActiveItem: false,
    activeIndex: undefined,
    fromWidth: 0
  },
  items: undefined,
  init: function() {
    this.items = this.element.find('> [data-role="accordion-item"]');

    if (this.items.length > 0) {
      this.windowWidth = window.innerWidth;
      if (this.options.activeIndex != undefined) {
        this.activate(this.options.activeIndex);
      }
      this.addDOMEvents();
    }

    this.element.addClass('initialized');
  },
  addDOMEvents: function() {
      var _self = this;

      this.items.children('[data-role="accordion-title"]').on('click', function(e) {
          if (!_self.options.fromWidth || (_self.options.fromWidth && _self.options.fromWidth >= _self.windowWidth)) {
              _self.activate($(this).closest('[data-role="accordion-item"]').index());
          }
      });

      if (typeof _self.options.fromWidth !== 'undefined') {
          $(document).on(PROJECT_NAMESPACE+'.window_width_changed', function(e, newValue){
            _self.windowWidth = newValue;
            _self.checkVisibility();
          });
      }
  },
  activate: function(index) {
      var act = this.items.get(index);

      if (act) {
          if (this.options.singleActiveItem) {
              this.element.find('.active').removeClass('active');
              setTimeout(function() {
                  $(act).toggleClass('active').children('[data-role="accordion-content"]').slideToggle();
              }, 500);
          }
          else {
              $(act).toggleClass('active').children('[data-role="accordion-content"]').slideToggle()
          }
      }
  },
  remove: function() {
      this.items.each(function() {
          $(this).find('[data-role="accordion-content"]').removeAttr('style');
          if ($(this).hasClass('active')) {
              $(this).removeClass('active');
          }
      })
  },
  checkVisibility: function() {
    if (this.options.fromWidth && this.windowWidth > this.options.fromWidth) {
        this.remove();
    }
  }
}


window.AccordionList = AccordionList;
