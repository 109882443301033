(function(wnd){
  function FormHandler(form, params) {
    try {
      if (!form || (form && form.length == 0)) {
        throw 'Container is required!';
      } else if (!form.is('form')) {
        throw 'Container must be a <form> element!';
      }

      this.form = form;
      if (params) {
        if (typeof params === 'string') {
          params = this.parseParams(params);
        }

        this.setParams(params);
      }

      this.init();
    } catch (error) {
      console.log(error);
    }
  }

  FormHandler.prototype = {
    form: undefined,
    ajax: false,
    sendedForm: false,
    timeout: 1500,
    reloadAfterSave: true,
    resetAfterSave: true,
    feedbackMode: 'message',
    scrollToFirstInvalidField: true,
    init: function() {
      this.setElements();
      this.addDOMEvents();

      this.form.addClass('initialized');
    },
    setElements: function() {
      this.button = this.form.find('[data-purpose="send-button"]');
    },
    addDOMEvents: function() {
      var _self = this;

      this.form.on('submit', function(e){
        if (_self.isAjax()) {
          e.preventDefault();
          _self.sendForm();
        }
      });

      if (this.button && this.button.length > 0) {
        this.button.click(function(){
          _self.form.submit();
        });
      }
    },
    setParams: function(params) {
      if (typeof params.ajax !== 'undefined') this.setAjax(params.ajax);
      if (typeof params.reloadAfterSave !== 'undefined') this.setReloadAfterSave(params.reloadAfterSave);
      if (typeof params.resetAfterSave !== 'undefined') this.setResetAfterSave(params.resetAfterSave);
      if (typeof params.scrollToFirstInvalidField !== 'undefined') this.setScrollToFirstInvalidField(params.scrollToFirstInvalidField);
      if (params.feedbackMode) this.setFeedbackMode(params.feedbackMode);
      if (params.timeout) this.setTimeoutValue(params.timeout);

      if (typeof params.handleSuccessSend === 'function') {
        this.handleSuccessSend = params.handleSuccessSend;
      }

      if (typeof params.beforeSend === 'function') {
        this.customBeforeSend = params.beforeSend;
      }
    },
    setAjax: function(ajax) {
      this.ajax = ajax;
    },
    isAjax: function() {
      return this.ajax;
    },
    setReloadAfterSave: function(reloadAfterSave) {
      this.reloadAfterSave = reloadAfterSave;
    },
    setResetAfterSave: function(resetAfterSave) {
      this.resetAfterSave = resetAfterSave;
    },
    setScrollToFirstInvalidField: function(scrollToFirstInvalidField) {
      this.scrollToFirstInvalidField = scrollToFirstInvalidField;
    },
    setFeedbackMode: function(feedback) {
      this.feedbackMode = feedback;
    },
    setTimeoutValue: function(timeout) {
      this.timeout = timeout;
    },
    sendForm: function() {
      if (!this.sendedForm) {
        var _self = this;

        this.sendedForm = true;

        var methodType = this.form.attr('method');
        if (!methodType) {
          methodType = 'post';
        }

        var datas = this.form.serialize();

        $.ajax({
          url: this.form.attr('action'),
          dataType:'json',
          type: methodType,
          data: datas,
          beforeSend: function() {
            _self.form.find('.has-error').removeClass('has-error');
            _self.form.find('.error-content').remove();

            if (typeof _self.customBeforeSend === 'function') {
              _self.customBeforeSend();
            }
          },
          success: function(response) {
            if (response.success) {
              _self.handleSuccessSend(response);

              $(document).trigger(PROJECT_NAMESPACE+'.success_form_submit', {
                form: _self.form,
                datas: datas,
                key: _self.form.data('key'),
                response: response
              });
            } else {
              if (typeof response.message !== 'undefined') {
                $(document).trigger(PROJECT_NAMESPACE+'.show_system_message', {
                  type:'error',
                  message: response.message
                });
              }

              if (typeof response.errors === 'object') {
                $(document).trigger(PROJECT_NAMESPACE+'.handle_form_errors', {
                  form: _self.form,
                  errors: response.errors,
                  scrollToFirstInvalidField: _self.scrollToFirstInvalidField
                });
              }

              $(document).trigger(PROJECT_NAMESPACE+'.errored_form_submit', {
                form: _self.form,
                errors: typeof response.errors !== undefined ? response.errors : false,
                datas: datas,
                key: _self.form.data('key'),
                response: response
              });
            }
          },
          complete: function() {
            setTimeout(function(){
              _self.sendedForm = false;
            }, 400);
          }
        });
      }
    },
    customBeforeSend: function() {},
    handleSuccessSend: function(response) {
      switch (this.feedbackMode) {
        case 'message':
          if (typeof response.message !== 'undefined') {
            $(document).trigger(PROJECT_NAMESPACE+'.show_system_message', {
              type:'success',
              message: response.message
            });
          }
          break;
        case 'confirmContent':
          var key = this.form.data('key');
          var confirmContent = $(document).find('[data-purpose="confirm-content"][data-key="'+key+'"]');
          if (confirmContent && confirmContent.length > 0) {
            this.form.hide();
            confirmContent.show();
          }
          break;
      }

      if (this.resetAfterSave) {
        this.form.find('input,textarea,select').each(function(){
          if ($(this).is(':checkbox') || $(this).is(':radio')) {
            $(this).prop('checked', false);
          } else {
            $(this).val('');
          }
        });
      }

      if (this.reloadAfterSave) {
        this.button.attr('disabled', true);
        var timeout = typeof response.timeout !== 'undefined' ? response.timeout : this.timeout;
        setTimeout(function(){
          if (typeof response.url !== 'undefined') {
            document.location.href = response.url;
          } else {
            document.location.reload();
          }
        }, timeout);
      }
    },
    parseParams: function(str) {
      var obj = {};
      var arr;
      var len;
      var val;
      var i;

      // Remove spaces before and after delimiters
      str = str.replace(/\s*:\s*/g, ':').replace(/\s*,\s*/g, ',');

      // Parse a string
      arr = str.split(',');
      for (i = 0, len = arr.length; i < len; i++) {
        arr[i] = arr[i].split(':');
        val = arr[i][1];

        // Convert a string value if it is like a boolean
        if (typeof val === 'string' || val instanceof String) {
          val = val === 'true' || (val === 'false' ? false : val);
        }

        // Convert a string value if it is like a number
        if (typeof val === 'string' || val instanceof String) {
          val = !isNaN(val) ? +val : val;
        }

        obj[arr[i][0]] = val;
      }

      return obj;
    }
  }


  wnd.FormHandler = FormHandler;
})(window);
