require('./core/jquery.selectric.js');
require('./core/accordion.js');
require('./CalculatorForm.js');
require('./FormHandler.js');
require('./SiteHelper.js');
require('./SiteEventHandler.js');
require('./MobileMenu.js');
require('./core/remodal.js');
require('./MessageHandler.js');

var pwWindowWidth = window.innerWidth;
var header = undefined;
var headerBorder = undefined;

$(document).ready(function(){
  header = $('.main-header');
  if (header && header.length === 1) {
    headerBorder = $('[data-purpose="header-scroll-border"]');
    if (headerBorder.length === 0) {
      headerBorder = header;
    }

    checkHeaderPosition();
    $(document).on(PROJECT_NAMESPACE+'.user_did_scroll', function(){
      checkHeaderPosition();
    });
  }

  $('.password-show-outer').on('click', function(){
    var target = $(this).data('target');
    if (target) {
      var item = $(target);
      if (item.length === 1) {
        $(this).toggleClass('active');
        var type = $(this).hasClass('active') ? 'text' : 'password';
        item.attr('type', type);
      }
    }
  });

  /** SiteHelper */
  initSiteHelper();

  initMailtoLinks();

  initCalculatorForm();

  initCustomSelects();

  initSiteEventHandler();

  initFormHandlers();

  /** Accordion */
  initAccordionLists();

  /** Mobil menü */
  initMobileMenu();

  initMessageHandler();
});

function checkHeaderPosition() {
  var scrollTop = $(window).scrollTop();
  var height = headerBorder.outerHeight(true) + headerBorder.offset().top;

  if (height <= scrollTop && !header.hasClass('opened-fix-header')) {
    header.addClass('opened-fix-header');
    $('body').addClass('opened-fix-header');
    $(document).trigger(PROJECT_NAMESPACE+'.header_position_changed');
  } else if (height > scrollTop && header.hasClass('opened-fix-header')) {
    $(document).trigger(PROJECT_NAMESPACE+'.header_position_changed');
    header.removeClass('opened-fix-header');
    $('body').removeClass('opened-fix-header');

    header.find('.submenu').hide(0, function(){
      var submenu = $(this);
      setTimeout(function(){
        submenu.removeAttr('style');
      }, 250);
    });
  }
}

function initSiteHelper()
{
  if (typeof SiteHelper !== 'undefined') {
    var params = {};

    if (typeof siteHelperParams === 'object') {
      params = $.extend({}, params, siteHelperParams);
    }

    window.siteHelperObj = new SiteHelper(params);
  }
}

function initMailtoLinks()
{
  $('.mailtolink:not(.initialized)').each(function(){
    $(this).on('click', function(){
      handleMailToLinkClick($(this));
    });
    $(this).addClass('initialized');
  });
}

function initCalculatorForm()
{
  var params = {};
  if (typeof calculatorFormParams !== "undefined") {
    params = $.extend(true, {}, params, calculatorFormParams);
  }

  $('[data-purpose="calculator-container"]:not(.initialized)').each(function(){
    new CalculatorForm($(this), params);
  });
}

function handleMailToLinkClick(item) {
  var href = item.attr('href');
  if (href == 'javascript:void(0)') {
    var hidden = item.find('.hidden-mail');
    if (hidden && hidden.length === 1) {
      item.attr('href', 'mailto:'+hidden.data('user')+'@'+hidden.data('domain'));
    }
  }
}

function svg(icon, className) {
  return '<svg class="icon '+icon+(className ? ' '+className : '')+'"><use xlink:href="#'+icon+'" /></svg>';
}

var customSelectCounter = 0;
var customSelectInitialized = 0;
var lazyLoadInterval = undefined;

function initCustomSelects()
{
  var selects = $('.custom-select select:not(initialized)');
  if (selects && selects.length > 0) {
    customSelectCounter = selects.length;
    customSelectInitialized = 0;

    selects.each(function(){
      var isCountrySelect = $(this).hasClass('country-select');

      $(this).on('selectric-init', function(){
        customSelectInitialized++;
        if (customSelectInitialized === customSelectCounter) {
          updateLazyLoad();
        }
      });

      $(this).selectric({
        optionsItemBuilder: function(itemData) {
          if (isCountrySelect) {
            return '<span class="country-select-item">'+(itemData.value.length > 0 ? '<span class="flag"><img data-src="/assets/img/svg/flags/'+itemData.value.toLowerCase()+'.svg" class="flag-icon lazy" alt="'+itemData.text+'" /></span>' : '') + '<span class="text">'+itemData.text+'</span></span>';
          } else {
            return '<span class="text">'+itemData.text+'</span>';
          }
        },
        labelBuilder: function(currItem) {
          if (isCountrySelect) {
            return '<span class="country-select-item">'+(currItem.value.length > 0 ? '<span class="flag"><img src="/assets/img/svg/flags/'+currItem.value.toLowerCase()+'.svg" alt="'+currItem.text+'" /></span>' : '') + '<span class="text">'+currItem.text+'</span></span>';
          } else {
            return '<span class="text">'+currItem.text+'</span>';
          }
        },
        onChange: function(element) {
          $(element).change();
        }
      });

      $(this).addClass('initialized');
    });
  }
}

function updateLazyLoad()
{
  lazyLoadInterval = setInterval(function(){
    if (typeof window.lazyLoadInstance !== 'undefined') {
      window.lazyLoadInstance.update();
      clearInterval(lazyLoadInterval);
    }
  }, 50);
}

function initFormHandlers()
{
  $('[data-purpose="general-form"]:not(.initialized)').each(function(){
    var key = $(this).data('key');
    var params = {};
    var options = $(this).data('options');
    if (options) {
      params = window.siteHelperObj.parseStringToObject(options);
    }

    if (key && typeof formHandlerParams === 'object' && typeof formHandlerParams[key] === 'object') {
      params = $.extend(true, {}, params, formHandlerParams[key]);
    }

    new FormHandler($(this), params);
  });
}

function initSiteEventHandler() {
  if (typeof SiteEventHandler !== 'undefined') {
    var params = {};

    if (typeof eventHandlerParams !== 'undefined') {
      params = $.extend({}, params, eventHandlerParams);
    }

    new SiteEventHandler(params);
  }
}

function initAccordionLists()
{
  $('.accordion-list:not(.initialized)').each(function(ind, el) {
		var options = {};
		var th = $(el);

		if (th.data('single-active-item') != undefined) options.singleActiveItem = false;
		if (th.data('active-index') != undefined) options.activeIndex = th.data('active-index');
    if (th.data('from-width') != undefined) options.fromWidth = parseInt(th.data('from-width'));

		new AccordionList(th, options);
  });
}

function initMobileMenu()
{
  if (typeof MobileMenu !== 'undefined') {
    var params = {
      createContent: false,
      type:'slide'
    };

    if (typeof mobileMenuParams === 'object') {
      params = $.extend({}, params, mobileMenuParams);
    }

    new MobileMenu($('.mobile-menu-btn'), params);
  }
}

function initMessageHandler()
{
  if (typeof MessageHandler !== 'undeinfed') {
    var params = {};
    if (typeof messageHandlerParams === 'object') {
      params = $.extend({}, params, messageHandlerParams);
    }

    window.messageHandlerObj = new MessageHandler(params);
  }
}

function openRemodalContent(id)
{
  var item = $('[data-remodal-id="'+id+'"]');
  if (item && item.length === 1) {
    item.remodal().open();
  }
}

function scrollToElement(selector, speed) {
  if (!speed) {
    speed = "normal";
  }

  var element = typeof selector === 'string' ? $(selector) : selector;

  if (element && element.length > 0) {
    var offset = $('body').hasClass('opened-fix-header') ? $('.main-header .fix-content').outerHeight(true) : $('.main-header').outerHeight(true);

    $('html, body').animate({scrollTop: element.offset().top - offset}, speed);
  }
}

window.svg = svg;
window.initMailtoLinks = initMailtoLinks;
window.initCustomSelects = initCustomSelects;
window.openRemodalContent = openRemodalContent;
window.scrollToElement = scrollToElement;
